<template>
    <div class="footer-top">
        <div class="thm-container">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <div class="single-footer-top">
                        <p><i class="bitmex-icon-envelope"></i><span>ایمیل: </span>atax@bitcoin.com</p>
                    </div><!-- /.single-footer-top -->
                </div><!-- /.col-md-4 -->
                <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <div class="single-footer-top">
                        <p><i class="bitmex-icon-phone-call"></i><span>شماره تماس: </span>041 33333333</p>
                    </div><!-- /.single-footer-top -->
                </div><!-- /.col-md-4 -->
                <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                    <div class="single-footer-top">
                        <p><i class="bitmex-icon-placeholder"></i><span>آدرس: </span>آذربایجان شرقی / تبریز/ سه راه امین</p>
                    </div><!-- /.single-footer-top -->
                </div><!-- /.col-md-4 -->
            </div><!-- /.row -->
        </div><!-- /.thm-container -->
    </div><!-- /.footer-top -->

    <footer class="site-footer">
        <div class="thm-container">
            <div class="row masonary-layout">
                <div class="col-md-3 col-sm-6 col-xs-12">
                    <div class="single-footer-widget">
                        <div class="title">
                            <h3>درباره</h3>
                        </div><!-- /.title -->
                        <ul class="links-list">
                            <li><a href="#">خانه</a></li>
                            <li><a href="#">مشاغل</a></li>
                            <li><a href="#">تیم ما</a></li>
                            <li><a href="#">ارتباط با ما</a></li>
                            <li><a href="#">آخرین اخبار</a></li>
                            <li><a href="#">مشاوره رایگان</a></li>
                        </ul><!-- /.links-list -->
                    </div><!-- /.single-footer-widget -->
                </div><!-- /.col-md-3 -->
                <div class="col-md-3 col-sm-6 col-xs-12">
                    <div class="single-footer-widget">
                        <div class="title">
                            <h3>بیت کوین</h3>
                        </div><!-- /.title -->
                        <ul class="links-list">
                            <li><a href="https://user.gbitex.com">شروع ثبت نام</a></li>
                            <li><a href="#">کیف پول</a></li>
                            <li><a href="#">خرید و فروش بیت کوین</a></li>
                            <li><a href="#">بازار ارز</a></li>
                            <li><a href="#">آمار روزانه</a></li>
                        </ul><!-- /.links-list -->
                    </div><!-- /.single-footer-widget -->
                </div><!-- /.col-md-3 -->
                <div class="col-md-3 col-sm-6 col-xs-12">
                    <div class="single-footer-widget">
                        <div class="title">
                            <h3>مجاز</h3>
                        </div><!-- /.title -->
                        <ul class="links-list">
                            <li><a href="#">راهنما</a></li>
                            <li><a href="#">سوالات متداول</a></li>
                            <li><a href="#">تبلیغات</a></li>
                            <li><a href="#">سیاست حفظ حریم خصوصی</a></li>
                            <li><a href="#">شرایط استفاده</a></li>
                        </ul><!-- /.links-list -->
                    </div><!-- /.single-footer-widget -->
                </div><!-- /.col-md-3 -->
                <div class="col-md-3 col-sm-6 col-xs-12">
                    <div class="single-footer-widget">
                        <div class="title">
                            <h3>اشتراک در </h3>
                        </div><!-- /.title -->
                        <form action="inc/mailchimp/subscribe.php" class="subscribe-form">
                            <input type="text" placeholder="آدرس ایمیل" />
                            <button type="submit" class="fa fa-check"></button>
                        </form><!-- /.subscribe-form -->
                    </div><!-- /.single-footer-widget -->
                </div><!-- /.col-md-3 -->
            </div><!-- /.row -->
        </div><!-- /.thm-container -->
    </footer><!-- /.site-footer -->

    <div class="footer-bottom">
        <div class="thm-container clearfix">
            <div class="pull-left copy-text">
              
            </div><!-- /.pull-left copy-text -->
            <div class="social pull-right">
                <a href="#" class="fa fa-twitter"></a><!-- -->
                <a href="#" class="fa fa-facebook"></a><!-- -->
                <a href="#" class="fa fa-youtube-play"></a><!-- -->
                <a href="#" class="fa fa-pinterest"></a>
            </div><!-- /.social -->
        </div><!-- /.thm-container -->
    </div><!-- /.footer-bottom -->
</template>
<script>
export default {

}
</script>
<style ></style>