<template >
    <div class="preloader">
        <div class="spinner"></div>
    </div><!-- /.preloader -->



    <header class="header header-home-one">
        <nav class="navbar navbar-default header-navigation stricky">
            <div class="thm-container clearfix">
                <!-- Brand and toggle get grouped for better mobile display -->
                <div class="navbar-header">
                    <button type="button" class="navbar-toggle collapsed bitmex-icon-menu" data-toggle="collapse"
                        data-target=".main-navigation" aria-expanded="false"> </button>
                    <router-link to="/" class="navbar-brand"> <img src="img/logo.png" alt="Awesome Image" /></router-link>
                </div>

                <nav-menu></nav-menu>
                <div class="right-side-box">
                    <a href="https://user.gbitex.com"><span class="icofont icofont-ui-user"></span><span> ورود/ثبت نام</span></a>
                    <!-- <a href="#test-search" class="search-icon popup-with-zoom-anim bitmex-icon-search"></a> -->
                    <!--
                -->
                </div><!-- /.right-side-box -->
            </div><!-- /.container -->
        </nav>
    </header><!-- /.header -->


    <section v-if="!isIndex" class="inner-banner gray-bg text-center">
        <div class="thm-container">
            <div class="breadcumb">
                <router-link to="/">خانه</router-link>
                <span class="sep">-</span>
                <span class="page-name">{{ name }}</span>
            </div><!-- /.breadcumb -->
            <h3>{{ title }}</h3>

      
                <p>{{ description }}</p>
                
        </div><!-- /.thm-container -->
    </section><!-- /.inner-banner -->
</template>
<script>
import NavMenu from './NavMenu.vue';
export default {
    components: {
        NavMenu
    },
    data() {
        return {
            title: "",
            path: "",
            isIndex: Boolean,
            name: "",
            description:""
        }
    },
  
    watch: {
        $route(to) {
            this.title = to.meta.title;
            this.description = to.meta.description;
            this.name = to.name || "Other Title";
            this.path = to.path;

            console.log(this.path)

            if (this.path == "/") {
                this.isIndex = true;
            } else {
                this.isIndex = false;
            }
           
        },
    },
}
</script>
<style>
.right-side-box a{
font-size: 16px !important  ;
}
</style>