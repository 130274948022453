import { createRouter, createWebHistory } from 'vue-router'
const routes = [
    {
        path: '/',
        name: 'خانه',
        component: () => import('./components/pages/HomePage.vue')
    },
    {
        path: '/about-us',
        name: 'درباره ما',
        meta:{title :'صفحه درباره ما'},
        component: () => import('./components/pages/AboutPage.vue')
    },
    {
        path: '/contact-us',
        name: 'ارتباط با ما',
        meta:{title :'صفحه ارتباط با ما'},
        component: () => import('./components/pages/ContactPage.vue'),
       
    },
    {
        path: '/help',
        name: 'راهنمای استفاده',
        meta:{title:'راهنمای استفاده'},
        component: () => import('./components/pages/HelpPage.vue')
    },
    {
        path: '/wage',
        name: 'کارمزد',
        meta:{title:'کارمزد'},
        component: () => import('./components/pages/WagePage.vue')
    },
    {
        path: '/rules',
        name: 'قوانین',
        meta:{title:"قوانین و شرایط ثبت نام در بازار ارز دیجیتال",description:"جی بیتکس تابع قوانین جمهوری اسلامی ایران بوده و بستری برای تبادل دارایی‌های دیجیتال مانند بیت کوین، لایت کوین و اتریوم با ریال می‌باشد. هیچ گونه تبادل ارزی اعم از خرید و فروش دلار یا سایر ارزهای کاغذی، در این بستر صورت نمی گیرد."},
        component: () => import('./components/pages/RulesPage.vue')
    },




]

const router = createRouter({
    history: createWebHistory(),
    mode: 'history',
    routes,

})
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem("userinfo") != null) {
            next()
            return
        }
        next('/signin')
    } else {
        next()
    }
})
export default router;