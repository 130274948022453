<template>
  <div class="preloader">
    <div class="spinner"></div>
  </div><!-- /.preloader -->
  <header-bar></header-bar>
  <router-view></router-view>
  <footer-bar></footer-bar>
  <div class="search_area zoom-anim-dialog mfp-hide" id="test-search">
    <div class="search_box_inner">
      <div class="input-group">
        <input type="text" class="form-control" placeholder="Search for...">
        <span class="input-group-btn">
          <button class="btn btn-default" type="button"><i class="bitmex-icon-search"></i></button>
        </span>
      </div>
    </div>
  </div>


  <section class="hidden-sidebar side-navigation">
    <a href="#" class="close-button side-navigation-close-btn fa fa-times"></a><!-- /.close-button -->
    <div class="sidebar-content">
      <h3>GBITEX <br /> Currency <br /> Html Template</h3>
      <p>Lorem ipsum dolor sit amet adipiscing elitn quis ex et mauris vulputate semper Etiam eget lacus dapibs ultricies
        diam vel sollicitudin.</p>
      <p class="contact-info">Inquiry@bitcoin.com <br /> 2800 256 508</p><!-- /.contact-info -->
      <div class="social">
        <a href="#" class="fa fa-twitter"></a>
        <a href="#" class="fa fa-facebook"></a>
        <a href="#" class="fa fa-youtube-play"></a>
        <a href="#" class="fa fa-pinterest"></a>
      </div><!-- /.social -->
    </div><!-- /.sidebar-content -->
  </section><!-- /.hidden-sidebar -->


  <div class="scroll-to-top scroll-to-target" data-target="html"><i class="fa fa-angle-up"></i></div>
</template>
<script>

import HeaderBar from './components/layout/HeaderBar.vue';
import FooterBar from './components/layout/FooterBar.vue';
export default {
  name: 'App',
  components: {
    HeaderBar,
    FooterBar
  }
}
</script>

<style></style>
